.post-page {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__content {
    padding: 20px 60px;
    width: 90%;

    @media screen and (max-width: 1024px) {
      padding: 0;
      width: 90%;
    }
  }

  &__content--breadcrumbs {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__content--breadcrumbs--link {
    text-decoration: none;
    color: #000000;
    margin-right: 5px;

    &:hover {
      color: #ff0000;
    }
  }

  &__content--breadcrumbs--title {
    margin-left: 5px;
  }

  &__content--return-button {
    display: none;

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }

  &__content--return-button--button {
    display: flex;
    align-items: center;
    font-size: 16px;
    border: none;
    text-decoration: none;
    color: #000000;
    margin: 20px 0;

    &:hover {
      color: #ff0000;
    }
    & img {
      margin-right: 5px;
    }
  }

  &__title {
    margin: 20px 0;

    @media screen and (max-width: 1024px) {
      margin: 10px 0;
    }
  }

  &__title--text {
    font-size: 46px;
    font-weight: 500;
    margin: 0;

    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }
  }

  &__post-date--wrapper {
    display: flex;
    margin: 20px 0 40px;
    align-items: center;
  }

  &__post-date--date {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #9f9f9f;
    display: flex;
    img {
      margin-right: 6px;
    }

    @media screen and (max-width: 1024px) {
      padding: 0 2px;
      & img {
        width: 10px;
        height: 17px;
      }

      & span {
        font-size: 10px;
      }
    }
  }

  &__post-date--point {
    margin: 0 8px;
    width: 5px;
    border-radius: 50%;
    height: 5px;
    background-color: #606060;

    @media screen and (max-width: 1024px) {
      width: 3px;
      height: 3px;
    }
  }

  &__post-date--submit {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
    display: flex;
    cursor: pointer;
    svg {
      margin-right: 4px;
    }
    &:hover {
      svg {
        fill: #fc6b58;
      }

      color: #fc6b58;
    }

    @media screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }

  &__post-date--submit__submit {
    @media screen and (max-width: 1024px) {
      width: 15px;
      height: 17px;
    }
  }

  &__title-image {
    width: 100%;

    & img {
      width: 70%;
    }

    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: flex-start;
      & img {
        width: 90%;
      }
    }
  }

  &__post-content {
    width: 70%;
    margin: 20px 0;

    @media screen and (max-width: 1024px) {
      line-break: anywhere;
      margin-bottom: 50px;
    }
  }

  &__loader {
    border: 16px solid #000000;
    border-radius: 50%;
    border-top: 16px solid #ff8859;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    right: 50%;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
