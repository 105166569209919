.wiki-page {
  display: flex;

  .item-result-search {
    margin: 0;
    flex-basis: calc(100% / 3 - 20px);
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;

    @media screen and (max-width: 1024px) {
      margin: 0;
      flex-basis: calc(100% / 1 - 50px);
      padding-bottom: 10px;
      border: none;
    }

    @media screen and (max-width: 650px) {
      margin: 0;
      flex-basis: calc(100% / 1 - 100px);
      padding-bottom: 10px;
      flex-direction: row;
      border: none;

      & img {
        width: 50%;
      }
    }
  }

  .date-info-item-result-search {
    @media screen and (max-width: 650px) {
      align-items: center;
      & img {
        width: 12px;
      }

      & div {
        font-size: 12px;
      }
    }
  }

  .info-item-result-search {
    @media screen and (max-width: 650px) {
      width: 157px;

      & .point-info-item-result-search {
        width: 3px;
        height: 3px;
      }

      & .submit-info-item-result-search {
        align-items: center;
        & svg {
          width: 15px;
          height: 15px;
        }

        & div {
          font-size: 10px;
        }
      }
    }
  }

  &__loader {
    border: 16px solid #000000;
    border-radius: 50%;
    border-top: 16px solid #ff8859;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    right: 50%;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__wrapper {
    padding: 60px 60px 0px 60px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      padding: 60px 20px 0 15px;
    }

    @media screen and (max-width: 650px) {
      padding: 60px 0 0 20px;
    }
  }

  &__head-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__head-text--logo {
    width: 250px;

    @media screen and (max-width: 650px) {
      width: 230px;
    }
  }

  &__head-text--secondary-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;

    @media screen and (max-width: 650px) {
      font-size: 17px;
      font-weight: 500;
    }
  }

  &__post--wrapper {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;

    @media screen and (max-width: 1024px) {
      justify-content: center;
    }

    @media screen and (max-width: 650px) {
      justify-content: flex-start;
    }
  }

  &__button--wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
      border-top: 2px solid #f5f5f5;
      margin: 0;
      padding-top: 30px;
    }

    @media screen and (max-width: 650px) {
      width: 95%;
      justify-content: flex-start;
    }
  }

  &__back-top {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button--show-more {
    width: 32%;
    background-color: #000000;
    color: #ffffff;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  &__button--back-top--wrapper {
    width: 100%;
    display: flex;
    border-top: 1px solid #f5f5f5;
    margin-top: 40px;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      margin-top: 30px;
    }
  }

  &__button--back-top {
    border: none;
    width: 100px;
  }
}
