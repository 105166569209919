.content-item-type-questionnaire {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-questionnaire {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-questionnaire {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description-item-type-questionnaire {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.btn-item-type-questionnaire {
  font-style: normal;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px;
  background: #000000;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 56px;

  &:hover {
    background-color: #FC6B58;
  }
}

.number-question-item-type-questionnaire {
  width: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  background: #FFFFFF;
  border-radius: 32px;
  margin-top: 24px;

  span {
    font-size: 18px;
  }
}

.item-question-item-type-questionnaire {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.container-radio-item-type-questionnaire {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.item-radio-item-type-questionnaire {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.text-item-radio-item-type-questionnaire {
  margin-left: 12px;
}

.item-radio-item-type-questionnaire input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-item-radio-item-type-questionnaire {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 34px;
  border: 1px solid black;
  box-sizing: border-box;
  //border-radius: 50%;
  border-radius: 6px;
}

.item-radio-item-type-questionnaire:hover input ~ .checkmark-item-radio-item-type-questionnaire {
  border: 1px solid black;
}

.item-radio-item-type-questionnaire input:checked ~ .checkmark-item-radio-item-type-questionnaire {
  background-color: black;
}

.checkmark-item-radio-item-type-questionnaire:after {
  content: "";
  position: absolute;
  display: none;
}

.item-radio-item-type-questionnaire input:checked ~ .checkmark-item-radio-item-type-questionnaire:after {
  display: block;
}

.item-radio-item-type-questionnaire .checkmark-item-radio-item-type-questionnaire:after {
  content: url('../../../../../assets/checkmarker.svg');
  position: absolute;
  top: 50%;
  width: 20px;
  height: 23px;
  left: 50%;
  transform: translate(-50%, -45%);
}

.container-switching-item-type-questionnaire {
  //display: flex;
  //justify-content: space-between;
  //width: 100%;
}

.btn-switching-item-type-questionnaire {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  //width: calc(50% - 8px);
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
  cursor: pointer;

  &.prev {
    &:hover {
      border-color: #FC6B58;
      color: #FC6B58;
    }
  }

  &.next {
    background-color: #000000;
    color: #FFFFFF;

    &:hover {
      background-color: #FC6B58;
      border-color: #FC6B58;
    }
  }


}
