.wrapper-my-statements-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-my-statements-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 0 0 0;
}

.location-site-my-statements {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  span {
    color: #606060;
  }
  a {
    color: #606060;
    text-decoration: none;
  }

  img {
    margin: 0 12px;
  }
}


.container-head-my-statements {
  display: flex;
}

.item-my-statements-head {
  display: flex;
  flex-direction: column;

  &.first {
    width: calc(100% - 320px);
    align-items: flex-start;
  }

  &.second {
    width: 320px;
    align-items: flex-end;
  }
}

.item-backmain-title-my-statements {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.btn-backmain-item-backmain-title-my-statements {
  text-decoration: none;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.separator-item-backmain-title-my-statements {
  height: 20px;
  width: 1px;
  margin: 0 16px;
  background-color: #dfdfdf;
}

.title-item-backmain-title-my-statements {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
}

.item-filter-all-my-statements {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.item-filter-my-statements-declare {
  margin-right: 12px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #FC6B58;
    border-color: #FC6B58;
  }

  &.active {
    background: #DFDFDF;
  }
}

.item-newstatement-my-statements {
  text-decoration: none;
  color: #000;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 32px;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.item-sort-my-statements {
  display: flex;
  margin-top: 16px;
}

.select-item-sort-my-statements {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  height: 30px;
}

.title-item-sort-my-statements {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #606060;
  margin-right: 16px;
}

.list-all-my-statements {
  display: flex;
  flex-wrap: wrap;
}

.item-my-statements {
  width: calc(25% - 18px);
  margin-top: 16px;
  margin-right: 24px;

  &:nth-child(4n) {
    margin-right: 0px;
  }
}

.wrapper-img-item-my-statements {
  position: relative;
  background: #F5F5F5;
  border-radius: 4px;
  height: 100px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.progress-status-item-my-statements {
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
}

.progress-item-my-statements {
  background-color: white;
  padding: 4px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 6px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-left: 6px;
  }
}

.status-item-my-statements {
  background-color: white;
  padding: 4px 5px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.title-item-my-statements {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-item-my-statements {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  margin-top: 4px;
}

@media screen and (max-width: 1024px) {
  .content-my-statements-ulex {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 918px) {
  .item-my-statements {
    width: calc((100% / 3) - 16px);
    margin-top: 16px;
    margin-right: 24px;

    &:nth-child(4n) {
      margin-right: 24px;
    }

    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  .container-head-my-statements {
    flex-direction: column;
  }
  .item-my-statements-head {
    display: flex;
    flex-direction: column;

    &.first {
      width: 100%;
    }

    &.second {
      width: 100%;
    }
  }
  .item-newstatement-my-statements {
    margin-top: 6px;
  }
}

@media screen and (max-width: 676px) {
  .item-my-statements {
    width: calc((100% / 2) - 8px);
    margin-top: 16px;
    margin-right: 16px;

    &:nth-child(3n) {
      margin-right: 16px;
    }

    &:nth-child(2n) {
      margin-right: 0px;
    }
  }

}
