.paymentModal {
  max-width: 428px;
  max-height: calc(100vh - 10px);
  width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
  }

  &__content {
    width: calc(100% - 40px);
    max-width: 428px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 32px;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #000000;

    span {
      color: #FC6B58;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    margin-top: 26px;
  }

  &__desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 16px;
    margin-top: 26px;
    margin-bottom: 26px;

    span {
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
      color: black;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 15px;
    }

    li {
      color: #000000;
    }
  }

  &__descSecond {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #202020;
    border-radius: 6px;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: white;

    &__logo {
      height: fit-content;
      margin-bottom: 32px;

      svg {
        max-width: 40px;
        height: fit-content;
      }
    }

    &__number {
      margin-bottom: 20px;
    }

    &__secondParam {
      display: flex;
      flex-direction: row;
      gap: 28px;
      margin-bottom: 26px;
    }

    &__data {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
    }

    &__input {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 1px solid #404040;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #808080;

      &.date {
        width: 100%;
        border-color: transparent;
      }

      input {
        text-align: start;
        padding: 16px 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        box-sizing: border-box;
        color: #FFFFFF
      }
    }

  }

  &__footer {
    padding: 10px;
    border-top: 1px solid #DFDFDF;
    margin-top: 52px;

    &.advice {
      margin-top: 32px;
    }

    &.payment {
      margin-top: 0;
    }

    &.statement {
      margin-top: 26px;
    }

    &.carDelete {
      display: flex;
    }
  }
}
