button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.app-btn {
  text-decoration: none;
  color: #000000;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  .loaderNew {
    width: 22px;
    height: 22px;
  }

  &.step {
    width: auto;
    padding: 14px 14px;
    border: 1px solid #DFDFDF;
    font-size: 16px;
    line-height: 16px;
    color: #606060;

    &:hover {
      border-color: #DFDFDF;
      color: #606060;
      svg {
        fill: #000000;
      }
    }

    &.active {
      border-color: #FC6B58;
      color: #FC6B58;
    }

    &.finish {
      border-color: #F5F5F5;
      color: #606060;
      background: #F5F5F5;
    }
  }

  &.settings-profile {
    display: flex;
    align-items: center;
    padding: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    border: 1px solid #DFDFDF;
    border-radius: 6px;
    cursor: pointer;
    width: fit-content;

    &.border-none {
      color: #808080;
      border: none;
    }

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: #FC6B58;
      border-color: #FC6B58;

      svg {
        fill: #FC6B58;
      }
    }
  }

  &.linked-account {
    padding: 8px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    width: fit-content;
    svg {
      margin-right: 8px;
    }
    &.bind {
      border: 1px solid #DFDFDF;
      border-radius: 6px;
      &:hover {
        border: 1px solid #FC6B58;
        color:  #FC6B58;
        svg {
          fill: #FC6B58;
        }
      }
    }
    &.untie {
      color: #606060;
      &:hover {
        color:  #FC6B58;
        svg {
          fill: #FC6B58;
        }
      }
    }


  }

  &.width {
    width: fit-content;
  }

  &.black {
    background: #000000;
    color: #FFFFFF;
  }

  &:hover {
    border-color: #FC6B58;
    color: #FC6B58;
    svg {
      fill: #FC6B58;
    }
  }

  &.active {
    border-color: #FC6B58;
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.disabledCar {
    border: 1px solid #DFDFDF;
    background: #DFDFDF;
    cursor: not-allowed;

    &:hover {
      color: white;
    }
  }

  &.clear {
    border: none;
  }

  &.diya{
    border: none;
    background: linear-gradient(89.98deg, #4F40FF 0.02%, #FFAEE8 52.59%, #FF8484 100.74%);
    &:hover {
      color: white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  &.payment {
    background: #383838;
    border-radius: 6px;
    color: white;
    border: 1px solid #383838;

    &:hover {
      background: #313131;
      border: 1px solid #313131;
      color: #FC6B58;

      svg path {
        fill: #FC6B58;
      }
    }
  }
}
