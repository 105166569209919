.content-item-type-partArticle {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-partArticle {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-partArticle {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: calc(100% - 36px - 10px);
  }

  span {
    border: 2px solid #000000;
    border-radius: 50%;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      svg {
        fill: #FC6B58;
      }

      border-color: #FC6B58;
    }
  }
}

.description-item-type-partArticle {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.btn-item-type-partArticle {
  font-style: normal;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px;
  background: #000000;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 56px;

  &:hover {
    background-color: #FC6B58;
  }
}

.entry-field-item-type-partArticle {

  &.platoon-company-battalion {
    justify-content: space-between;
    display: flex;

    span {
      width: calc((100% / 3) - 4px);
    }
  }

  input {
    box-sizing: border-box;
    padding: 16px;
    outline: none;
    margin-top: 12px;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    border-radius: 0;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.container-select-item-type-partArticle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.title-entry-field-item-type-partArticle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
