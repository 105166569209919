.wrapper-add-car-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;

  &.modal {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}

.headerAddCar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding: 16px 20px;
  background: linear-gradient(89.98deg, #4F40FF 0.02%, #FFAEE8 52.59%, #FF8484 100.74%);

  &__lines {
    display: flex;
    gap: 6px;

    &__line {
      width: 32px;
      height: 6px;
      background: #FFFFFF;
      border-radius: 32px;
      opacity: 0.6;

      &.active {
        opacity: 1;
      }
    }
  }

  &__step {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 4px 12px;
    background: #000000;
    border-radius: 32px;
    color: #FFFFFF;
  }
}

.content-add-car-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  padding: 32px 0 32px 0;
  flex-direction: column;
}

.title-add-car {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: -0.02em;
}

.subtitle-add-car {
  background: #EAEAEA;
  border-radius: 6px;
  padding: 4px 12px;
  width: fit-content;
  margin-top: 12px;
  color: #606060;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.description-add-car {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
  margin-top: 24px;
}

.container-input-brand-model {
  display: flex;
  margin-top: 46px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .addCarFiles {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__photo {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
    }
  }

}

.item-input-brand-model {
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
  max-width: 388px;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    outline: none;
    padding: 16px 0 16px 16px;
  }
}

.container-number-owner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 32px;
}

.item-number-add-car {
  display: flex;
  flex-direction: column;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 16px 24px;
    width: calc(170px - 48px);
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    text-transform: uppercase;
  }
}

.item-owner-add-car {
  margin-top: calc(18px + 16px);
  margin-left: 16px;
  display: flex;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 6px;
  }
}

.container-descSecure {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808080;

  svg {
    max-width: 14px;
  }

  svg path {
    fill: #808080;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  outline: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FC6B58;
}


input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container-btn-add-car {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .app-btn {
    max-width: 200px;
  }
}

.btn-add-car {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid #000000;
  border-radius: 6px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  svg {
    rotate: 180deg;
    //margin-right: 10px;
  }

  &:hover {

    color: #FC6B58;
    border-color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.btn-close-add-car {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  margin-top: 26px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #FC6B58;
  }
}

@media screen and (max-width: 1024px) {
  .content-add-car-ulex {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 918px) {
  .content-add-car-ulex {
    max-width: 388px;
  }

  .container-btn-add-car {
    .app-btn {
      max-width: none;
    }
  }
}
