.fileInput {
  .custom-dragger {
    .ant-upload-disabled {
      cursor: pointer;
      //color: ;
      color: rgba(0, 0, 0, 1);
    }
    .ant-upload-drag {
      min-width: 160px;
      max-width: 160px;
      min-height: 164px;
      max-height: 164px;
      border: 1px dashed transparent;
      background: #F5F5F5;

      &:hover {
        border-color: transparent;
      }
    }

    .ant-upload-btn {
      padding: 8px;
    }

    &.preview {
      .ant-upload-drag {
        border: 1px solid rgba(234, 234, 234, 1);
        background: none;

        &:hover {
          border: 1px solid rgba(234, 234, 234, 1);
        }
      }

      .fileInput__beforeUpload {
        background: none;
        border-color: transparent;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #9F9F9F;
          margin-top: 0;
        }
      }
    }
  }

  &__beforeUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    position: relative;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    transition: all 0.2s ease-in-out;

    border: 1px dashed rgba(159, 159, 159, 1);
    border-radius: 8px;

    &:hover {
      border: 1px dashed #FC6B58;
    }

    &__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000000;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      //width: 50px;
      max-width: 130px;
    }

    p {
      margin: 10px 0 4px 0;
    }

    &__types {
      display: flex;
      flex-direction: row;
      gap: 3px;

      div {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 1px 4px;
        background: #DFDFDF;
        border-radius: 12px;
      }
    }

    &__corner {
      position: absolute;
      left: -10px;
      top: -9px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #DFDFDF;
      border-radius: 8px 0;
      min-width: 36px;
      min-height: 36px;

      svg path {
        fill: #404040;
      }

    }

    &__eye {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      background: #EAEAEA;
      border-radius: 32px;
    }

    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border: 1px solid #DFDFDF;
      border-radius: 6px;

      &:hover {
        background: #FC6B58;
        color: white;

        //svg {
        //  fill: #FC6B58;
        //}

        svg path {
          stroke: white;
        }
      }
    }
  }
}
