.modalPhotoPreview {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: rgba(240, 240, 244, 0.85);

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 24px 20px;
    gap: 20px;
    width: calc(100% - 40px);
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 5px;
      transform: scale(1.3);
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 300px;
    }

    svg {
      rotate: 45deg;
    }
  }

  &__image {
    max-width: 80vw;
    max-height: 70vh;

    img {
      width: 100%;
      max-width: 400px;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: #000000;
    border-radius: 32px;
    color: #F5F5F5;
    width: fit-content;

    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.05em;


    svg path {
      stroke: #F5F5F5;
    }

    &:hover {
      background: #FC6B58;
      color: white;

      svg path {
        stroke: white;
      }
    }
  }
}
