.wrapper-more-data-ulex {
  max-width: 700px;
  max-height: calc(100vh - 10px);
  //width: 100%;
  width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-more-data-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;

  &.step {
    background-color: transparent;

  }
}

.content-more-data-ulex {
  width: calc(100% - 40px);
  max-width: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  //padding: 32px 0 32px 0;
  padding-top: 32px;

  &.step {
    align-items: center;
  }
}

.header-more-data {
  display: flex;
  align-items: center;
  gap: 15px;
  //margin-bottom: 52px;
  margin-bottom: 21px;

  .separator {
    height: 15px;
    width: 2px;
    background: black;
  }
 }

.steps-more-data {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.title-more-data {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: -0.02em;
  margin-bottom: 26px;
}

.desc-more-data {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}

.buttons-more-data {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 52px;

  &.edit {
    margin-top: 0;
  }
}

.footer-more-data {
  padding: 10px;
  border-top: 1px solid #DFDFDF ;
  margin-top: 26px;
}

@media screen and (max-width: 390px) {
  .steps-more-data {
    .step {
      width: auto;
      padding: 8px 8px;
      border: 1px solid #DFDFDF;
      font-size: 14px;
      line-height: 14px;
      color: #606060;
    }
  }
}
