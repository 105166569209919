.change-password-modal {
  width: 300px;
  padding: 20px 20px 10px;
  background: white;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__form {
    &-item {
      width: 100%;
      margin-bottom: 10px;

      small {
        color: #FC6B58;
      }

      input {
        width: 100%;
        color: #000000;
        text-align: start;
        padding: 16px 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #DFDFDF;
      }
    }
  }

  &__footer {
    padding: 10px;
    border-top: 1px solid #DFDFDF;
    margin-top: 26px;
  }
}

.wrapper-settings-profile-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;
}

.content-settings-profile-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  padding: 32px 0 8px 0;
  flex-direction: column;
}

.title-content-settings-profile {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  display: flex;
  margin-bottom: 32px;
}

.body-settings-profile {
  display: flex;
}

.container-settings-profile-body {
  &.first {
    width: calc(166px + 58px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &.second {
    width: calc(100% - 166px - 58px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.avatar-settings-profile {
  width: 166px;
  height: 166px;
  border-radius: 50%;
  background-color: #FC6B58;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-setting-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //align-items: center;

  .draggerAvatar {
    .ant-upload {
      &:hover {
        border-color: #FC6B58;
      }
    }

    .ant-upload-btn {
      padding: 10px;

      span {
        padding: 10px;
      }
    }

    .ant-upload-drag-container {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-btn-primary {
    background-color: #FC6B58;

    &:hover {
      background-color: #ff8576;
    }
  }
}

.column-settings-profile {
  width: calc(50% - 10px);
}

.item-settings-profile {
  border-top: 1px solid #DFDFDF;
  padding: 26px 0;
}

.title-item-settings-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }
}

.description-item-settings-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  max-width: 475px;
  margin-bottom: 26px;
}

.container-btns-settings-profile {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.btn-settings-profile {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  cursor: pointer;

  &.border-none {
    color: #808080;
    border: none;
  }

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;
    border-color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.note-item-settings-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606060;
}

.container-diya-linked-account {
  position: relative;
  padding: 10px 20px 20px 20px;
  margin-bottom: 32px;

}

.container-diya-linked-account::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  border: 2px solid transparent;
  background: linear-gradient(89.98deg, #4F40FF 0.02%, #FFAEE8 52.59%, #FF8484 100.74%) border-box;
  mask-composite: exclude;
}

.title-others-linked-accounts {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #606060;
  margin-bottom: 24px;
}

.container-others-linked-accounts {
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  padding: 10px 20px;

}

.item-linked-account {
  display: flex;
  align-items: center;
  height: 66px;
  justify-content: space-between;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.first-item-linked-account {
  display: flex;
  flex-direction: column;
}

.title-img-linked-account {
  display: flex;

}

.title-linked-account {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

}

.date-linked-account {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606060;
}

.btn-linked-account {
  padding: 8px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &.bind {
    border: 1px solid #DFDFDF;
    border-radius: 6px;

    &:hover {
      border: 1px solid #FC6B58;
      color: #FC6B58;

      svg {
        fill: #FC6B58;
      }
    }
  }

  &.untie {
    color: #606060;

    &:hover {
      color: #FC6B58;

      svg {
        fill: #FC6B58;
      }
    }
  }


}

.note-linked-account {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606060;

}

@media screen and (max-width: 1024px) {
  .content-settings-profile-ulex {
    width: calc(100% - 40px);
  }
  .container-settings-profile-body {
    &.first {
      width: calc(166px + 32px);
      display: flex;
    }

    &.second {
      width: calc(100% - 166px - 32px);
    }
  }
}

@media screen and (max-width: 918px) {
  .content-settings-profile-ulex {
    max-width: 388px;
  }
  .body-settings-profile {
    flex-direction: column;
  }
  .container-settings-profile-body {
    &.first {
      align-items: center;
      width: 100%;
      margin-bottom: 32px;
    }

    &.second {
      width: 100%;
    }
  }
  .avatar-settings-profile {
    width: 121px;
    height: 121px;
  }
  .column-settings-profile {
    width: 100%;
  }

}
