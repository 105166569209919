.wrapper-search-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;
}

.content-search-ulex {
  max-width: 1320px;
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  padding: 32px 0 32px 0;
  align-items: center;
}

.item-input-search {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid #DFDFDF;
  outline: none;
  padding: 16px 0;
  max-width: 640px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.item-example-search {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #808080;
  text-align: center;

  span {
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #FC6B58;
    }
  }
}

.item-qty-resalt-search {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #808080;
}

.container-result-search {
  width: 100%;
  margin-top: 42px;
  margin-bottom: 27px;
  border-bottom: 1px solid #DFDFDF;
  display: flex;
  flex-wrap: wrap;
}

.item-result-search-start {

  height: 258px;
}

.item-result-search {
  display: flex;
  flex-direction: column;
  width: calc(25% - 18px);
  margin-right: 24px;

  &:nth-child( 4) {
    display: flex;
    margin: 0;
    margin-bottom: 32px;
  }

  margin-bottom: 32px;
}

.item-result-search-null {
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #808080;

}

.img-item-result-search {
  width: 100%;
  height: 136px;
  background-color: rgba(255, 0, 0, 0.219);
  border-radius: 6px;
  object-fit: cover;
}

.title-item-reult-search {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;

  &:hover {
    color: #FC6B58;
  }
}

.info-item-result-search {
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.date-info-item-result-search {
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9F9F9F;
  display: flex;

  img {
    margin-right: 6px;
  }
}

.point-info-item-result-search {
  margin: 0 8px;
  width: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #606060;

}

.submit-info-item-result-search {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  display: flex;
  cursor: pointer;

  svg {
    margin-right: 4px;
  }

  &:hover {
    svg {
      fill: #FC6B58;
    }

    color: #FC6B58;
  }
}

.info-search-wiki {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #606060;
}

.item-all-resalt-search {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    color: #FC6B58;
  }
}

@media screen and (max-width: 1439px) {
  .content-search-ulex {
    max-width: 888px;
  }
  .item-result-search {
    width: calc(50% - 12px);

    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(4) {
      margin-right: 0;
    }
  }
  .img-item-result-search {
    height: 178px;
  }
}


@media screen and (max-width: 1024px) {
  .content-search-ulex {
    width: calc(100% - 40px);
  }
}


@media screen and (max-width: 918px) {
  .content-search-ulex {
    max-width: 388px;
  }
  .item-result-search {
    width: 100%;
    margin-right: 0;
    flex-direction: row;
    margin-bottom: 24px;
  }
  .img-item-result-search {
    width: 121px;
    height: 96px;
    margin-right: 10px;
  }
  .title-item-reult-search {
    margin-top: 0;
    line-height: 30px;
  }
  .item-result-search-start {
    height: 170px;
  }
  .item-result-search-null {
    height: 170px;
    font-size: 20px;
    line-height: 24px;
  }
}
