.wrapper-creating-statement-ulex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-content-creating-statement-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;

  &.step {
    background-color: transparent;
  }

  &.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 100px;
  }
}

.content-creating-statement-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0 32px 0;

  &.step {
    align-items: center;
  }
}

.location-site-creating-statement {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  span {
    color: #606060;
  }
  a {
    color: #606060;
    text-decoration: none;
  }

  img {
    margin: 0 12px;
  }
}

.container-head-creating-statement {
  display: flex;
}

.item-creating-statement-head {
  display: flex;
  flex-direction: column;

  &.first {
    width: calc(100% - 200px);
    align-items: flex-start;
  }

  &.second {
    width: 200px;
    align-items: flex-end;
  }
}

.item-backmain-title-creating-statement {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.btn-backmain-item-backmain-title-creating-statement {
  text-decoration: none;
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.separator-item-backmain-title-creating-statement {
  height: 20px;
  width: 1px;
  margin: 0 16px;
  background-color: #dfdfdf;
}

.title-item-backmain-title-creating-statement {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
}

.item-progress-step-creating-statement {

  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606060;
}

.item-container-save-statement {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  margin-top: 32px;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

}

.container-item-creating-statement {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-returned-creating-statement {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #006300;
  }
}

.point-step-creating-statement {
  margin-right: 32px;
  width: 64px;
  position: relative;

  div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-left: 3px dashed #FFFFFF;
    height: 100%;
  }

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 64px;
    height: 64px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
  }

  &.point-disable {
    span {
      background-color: #f3f3f3;
      color: #606060;
    }
  }
}

.item-step-creating-statement {
  max-width: 648px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.arrow-next-creating-statement {
  margin: 16px 0;

  &.arrow-disable {
    opacity: 0.4;
  }
}

//------------------step-inactive-----------------//

.content-item-step-inactive {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}


.title-item-step-inactive {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: calc(100% - 36px - 10px);
  }

  span {
    border-radius: 50%;
    background-color: white;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      svg {
        fill: white;
      }

      background-color: #FC6B58;
    }
  }
}

.description-item-step-inactive {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.55;
}


@media screen and (max-width: 1024px) {
  .content-creating-statement-ulex {
    width: calc(100% - 40px);
  }
}


@media screen and (max-width: 918px) {
  .container-head-creating-statement {
    flex-direction: column;
  }
  .item-creating-statement-head {
    display: flex;
    flex-direction: column;

    &.first {
      width: 100%;
    }

    &.second {
      width: 100%;
    }
  }
  .item-container-save-statement {
    margin-top: 16px;
  }
}


@media screen and (max-width: 676px) {
  .point-step-creating-statement {
    display: none;
  }
}






