.how-to-choose {
  display: flex;
  max-width: 430px;
  max-height: calc(100vh - 10px);
  flex-direction: column;
  width: 430px;
  align-items: center;
  background-color: #ffffff;

  &__wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header {
    & > h2 {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &__content {
    &__text--regular {
      font-weight: 500;
    }
  }

  &__footer {
    border-top: 1px solid #ededed;
    display: flex;
  }
}
