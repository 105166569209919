.wrapper-my-cars-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-my-cars-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 0 0 0;
}


.location-site-my-cars {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  span {
    color: #606060;
  }

  img {
    margin: 0 12px;
  }
}


.container-head-my-cars {
  display: flex;
}

.item-my-cars-head {
  display: flex;
  flex-direction: column;

  &.first {
    width: calc(100% - 200px);
    align-items: flex-start;
  }

  &.second {
    width: 200px;
    align-items: flex-end;
  }
}


.item-backmain-title-my-cars {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.btn-backmain-item-backmain-title-my-cars {
  color: #000000;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.separator-item-backmain-title-my-cars {
  height: 20px;
  width: 1px;
  margin: 0 16px;
  background-color: #dfdfdf;
}

.title-item-backmain-title-my-cars {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
}

.item-container-add-car {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 32px;

  svg {
    transform: scaleX(-1);
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

}

.container-swiper-my-cars {
  // width: calc(100% - 32px);
  // margin: 0 auto;
  width: 100%;
  margin-top: 32px;
  // max-width: 888px;
}

.swiper-my-cars {
  width: 100%;
  height: calc(384px + 32px + 20px);
}

.swiper-wrapper {
  display: flex;
}

.item-slide-my-cars {
  margin-bottom: 24px;
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  width: 280px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 404px !important;
  padding: 28px;
  position: relative;

  img {

    margin: 26px 0;
  }
}

.delete-number-item-slide-my-cars {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #F0F0F4;
  border-radius: 6px;
  padding: 10px;

  &:hover {
    svg path {
      stroke: #FC6B58;
    }
  }
}

.car-number-item-slide-my-cars {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 11px 28px;
}

.car-name-item-slide-my-cars {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.owner-item-slide-my-cars {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  margin-top: 12px;

  img {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 8px;
  }
}

.separator-item-slider-my-cars {
  border-bottom: 1px solid #DFDFDF;
  width: 100%;
  margin-top: 26px;
}


.btn-item-slider-my-cars {
  border-radius: 6px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}


@media screen and (max-width: 1024px) {
  .content-my-cars-ulex {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 918px) {
  .container-head-my-cars {
    flex-direction: column;
  }
  .item-my-cars-head {
    display: flex;
    flex-direction: column;

    &.first {
      width: 100%;
    }

    &.second {
      width: 100%;
    }
  }
  .item-container-add-car {
    margin-top: 16px;
  }
}
