.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    .modalWrapper__content {
      max-height: 100vh;
      max-width: 100vw;
    }
  }

  &__bg {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: -1;
    background: rgba(0, 0, 0, 0.24);
  }

  &__content {
    position: absolute;
    overflow-x: hidden;
    z-index: 1;
    max-height: calc(100vh - 10px);
    max-width: calc(100vw - 10px);
    //width: 100%;
    //height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
