.content-item-type-circumst {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-circumst {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-circumst {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description-item-type-circumst {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.btn-item-type-circumst {
  font-style: normal;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px;
  background: #000000;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 56px;

  &:hover {
    background-color: #FC6B58;
  }
}

.entry-field-item-type-circumst {
  .placeholder-field-item-type-circumst {
    width: 100%;
    min-height: 100px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    cursor: pointer;
    outline: 0!important;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 6px;
  }
}

.container-select-item-type-circumst {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.title-entry-field-item-type-circumst {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
