.wrapper-registration-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
  }
}

.content-registration-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  padding: 32px 0 32px 0;
  align-items: flex-end;
}

.container-registration {
  &.first {
    width: 420px;
  }

  &.second {
    width: calc(100% - 400px);
    max-width: 640px;
  }
}

.item-registration-title {
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 72px;
  margin-bottom: 40px;
}

.item-registration-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  width: 100%;
  max-width: 355px;
  margin-bottom: 40px;
}

.item-registration-rights {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #606060;
  width: 100%;
  max-width: 355px;
  border: none;
  text-align: start;

  &:hover {
    //color: #fc6b58;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.item-registration-link-authorization {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;

  span {
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #fc6b58;
    }
  }
}

.item-registration-link-authorization-mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
  display: none;

  span {
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #fc6b58;
    }
  }
}

.form-registration {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    padding: 16px 0 16px 16px;
    margin-bottom: 12px;
  }
}

.item-registration {
  display: flex;
  flex-direction: column;
}

.number-registration-note {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
  margin-bottom: 12px;
}

.btn-form-registration {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #fc6b58;
  }
}

.item-or-registration {
  position: relative;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 12px;
}

.title-or-registration {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #808080;
  background-color: white;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}

.line-or-registration {
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.item-other-options-registration {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
  row-gap: 12px;
}

.other-option-registration {
  text-decoration: none;
  color: #000000;
  margin-top: 12px;
  width: calc(50% - 11px);
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #fc6b58;
    color: #fc6b58;

    svg {
      fill: #fc6b58;
    }
  }

  &.diya {
    border: none;
    background: linear-gradient(89.98deg, #4f40ff 0.02%, #ffaee8 52.59%, #ff8484 100.74%);

    &:hover {
      color: white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.title-other-option-registration {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 918px) {
  .content-registration-ulex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 72px 0 54px 0;
  }
  .container-registration {
    &.first {
      width: calc(100% - 40px);
      max-width: 388px;
    }

    &.second {
      width: calc(100% - 40px);
      max-width: 388px;
    }
  }

  .item-other-options-registration {
    display: flex;
  }

  .item-registration-title {
    font-size: 46px;
    line-height: 52px;
    margin-bottom: 32px;
  }
  .item-registration-info {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 54px;
  }

  .item-registration-link-authorization {
    display: none;
  }

  .item-registration-link-authorization-mobile {
    display: block;
    text-align: center;
    margin-top: 24px;
  }
  .item-or-registration {
    margin-top: 32px;
    margin-bottom: 20px;
  }
  .other-option-registration {
    width: 100%;
  }
  .btn-back-start-authorization {
    margin-top: 32px;
  }
}
