.diyaQR {
  max-width: 428px;
  max-height: calc(100vh - 10px);
  width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;
  }

  &__content {
    width: calc(100% - 40px);
    max-width: 428px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 32px;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: center;
    margin-bottom: 24px;
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #404040;
  }

  &__code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #404040;
  }

  &__footer {
    padding: 10px;
    border-top: 1px solid #DFDFDF ;
    margin-top: 26px;
  }
}
