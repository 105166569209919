.finishStep {
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: 32px 20px;
  margin-bottom: -50px;
  font-style: normal;

  &__title {
    display: flex;
    align-items: center;
    gap: 14px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #606060;
    margin-bottom: 26px;
  }
}
