.content-item-type-option {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-option {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-option {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: calc(100% - 36px - 10px);
  }

  span {
    border: 2px solid #000000;
    border-radius: 50%;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      svg {
        fill: #FC6B58;
      }

      border-color: #FC6B58;
    }
  }
}

.description-item-type-option {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.options-type-option {
  margin-top: 54px;
  display: flex;
  //border: 1px solid #000000;
  //border-radius: 6px;
}
.container-select-item-type-option {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}
.container-select-item-type-option:nth-child(1) {
  margin-right: 5px;
}

.container-button-type-option {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn-type-option {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
  cursor: pointer;

  &.prev {
    &:hover {
      border-color: #FC6B58;
      color: #FC6B58;
    }
  }

  &.next {
    background-color: #000000;
    color: #FFFFFF;

    &:hover {
      background-color: #FC6B58;
      border-color: #FC6B58;
    }
  }


}

.dropdown-item {
  width: 100%;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #000000;
  border-radius: 6px;
  appearance: none; /* Removes the default styling of select in some browsers */
  -webkit-appearance: none; /* Removes the default styling of select in Safari */
  -moz-appearance: none; /* Removes the default styling of select in Firefox */
  background-color: white;
  color: black;
}
.dropdown-item:nth-child(1) {
  margin-right: 5px;
}

.item-options-type-option {
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.item-options-type-option {
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.separator-type-option {
  width: 100%;
  height: 1px;
  background-color: black;
}


@media screen and (max-width: 918px) {
  .content-item-type-option {
    padding: 32px;
  }
}

@media screen and (max-width: 676px) {
  .content-item-type-option {
    padding: 32px 24px;
  }
}
