@import "valiables";

body {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overscroll-behavior: none;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

svg, img {
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wrapper-app-ulex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.blackout {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
  background: #F0F0F4;
  opacity: 0.65;
  cursor: pointer;
}
.wrapper-over-content-ulex {
  width: 100%;
  position: relative;
}

.wrapper-content-ulex {
  width: 100%;
  height: calc(100% - 72px);
  top: 72px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
input {
  border-radius: 0;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}




