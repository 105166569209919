.item-radio-item-type-questionnaire {
  margin-bottom: 0;
}

.itemStep3 {

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    letter-spacing: -0.02em;
    //margin-bottom: 20px;
  }

  &__label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .itemStep3__checkmark:after {
        display: block;
      }
      &:checked ~ .itemStep3__checkmark {
        background: black;
      }
    }

    &__text {
      margin-left: 12px;
    }

    &__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 34px;
      width: 34px;
      border: 1px solid black;
      box-sizing: border-box;
      border-radius: 6px;

      &:after {
      //content: "";
      //position: absolute;
      //display: none;

        content: url('../../../../../assets/checkmarker.svg');
        position: absolute;
        top: 50%;
        width: 20px;
        height: 23px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    }

  }
}

input {
  border:none;
  background:none;
  outline:none;
  padding:0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.wrapper-step {
  display: flex;
  flex-direction: column;
}

.items-input-step {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 52px 0;

  &__blockInputs {
    display: flex;
    gap: 12px;
  }
}

.item-input-step {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #DFDFDF ;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  &.regiment {
    border-bottom: 1px solid transparent;
  }

  input {
    color: #000000;
    text-align: start;
    width: 70%;
    padding: 16px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.buttons-step {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-step {
  padding: 10px;
  border-top: 1px solid #DFDFDF ;
  margin-top: 26px;
}
