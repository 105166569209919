.wrapper-profile-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;
}

.content-profile-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  padding: 32px 0 26px 0;
  flex-direction: column;
}

.title-content-profile {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  display: flex;
  margin-bottom: 32px;
}

.body-profile {
  display: flex;
  border-bottom: 1px solid #DFDFDF;
}

.container-profile-body {
  &.first {
    width: calc(166px + 58px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &.second {
    width: calc(100% - 166px - 58px);
  }
}

.avatar-profile {
  width: 166px;
  height: 166px;
  border-radius: 50%;
  background-color: #FC6B58;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.welcone-user-profile-mobile {
  display: none;
  flex-direction: column;
  margin-left: 24px;
}

.welcome-user-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #606060;
}

.name-user-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.container-profile-body-filter-declare {
  border-bottom: 1px solid #DFDFDF;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  position: relative;
}

.content-profile-body-filter-declare {
  display: flex;
  align-items: center;

  &.first {
    width: calc(100% - 160px);
    align-items: flex-start;
  }

  &.second {
    color: #000000;
    text-decoration: none;
    position: absolute;
    right: 0;
    cursor: pointer;
    align-items: flex-start;
    margin-top: 6px;

    svg {
      margin-left: 8px;
    }

    &:hover {
      color: #FC6B58;

      svg {
        fill: #FC6B58;
      }
    }
  }
}

.title-profile-body-filter-declare {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  width: 90px;
  align-items: center;
}

.separator-profile-body-filter-declare {
  width: 1px;
  height: 30px;
  margin: 0 12px;
  background-color: #DFDFDF;
  display: flex;
}

.list-profile-body-filter-declare {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 90px - 24px);
}

.item-profile-body-filter-declare {
  margin-right: 12px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #FC6B58;
    border-color: #FC6B58;
  }

  &.active {
    background: #DFDFDF;
  }
}

.title-all-list-declare {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.container-profile-body-list-declare {
  padding: 32px 0;
}

.profile-body-list-declare {
  display: flex;
  width: 100%;
}

.swiper-profile {
  margin-left: 0;
  width: 100%;
}

.item-my-declare-profile {
  width: 170px;
  margin-right: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.wrapper-img-item-declare-profile {
  position: relative;
  background: #F5F5F5;
  border-radius: 4px;
  height: 100px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.progress-item-declare-profile {
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: white;
  padding: 4px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-left: 6px;
  }
}

.title-item-declare-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-item-declare-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  margin-top: 4px;
}

.profile-body-list-declare-note {
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606060;
  width: 100%;
  max-width: 850px;
}

.footer-profile {
  display: flex;
  justify-content: space-between;
  padding-top: 26px;
}

.container-profile-footer {
  &.first {
    display: flex;
    align-items: center;
  }

  &.second {
    display: flex;
    align-items: center;
  }
}

.item-btn-footer-profile {
  text-decoration: none;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin-left: 32px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: #FC6B58;
  }
}

.user-name-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.container-language-network-profile {
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  padding: 9px 32px 9px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin-left: 24px;
}

@media screen and (max-width: 1024px) {
  .content-profile-ulex {
    width: calc(100% - 40px);
  }

  .container-profile-body {
    &.first {
      width: calc(166px + 32px);
      display: flex;
    }

    &.second {
      width: calc(100% - 166px - 32px);
    }
  }
}

@media screen and (max-width: 918px) {
  .content-profile-ulex {
    max-width: 388px;
    padding: 32px 0;
  }
  .body-profile {
    flex-direction: column;
  }
  .container-profile-body {
    &.first {
      align-items: center;
      width: 100%;
      margin-bottom: 32px;
    }

    &.second {
      width: 100%;
    }
  }
  .avatar-profile {
    width: 121px;
    height: 121px;
  }
  .welcone-user-profile-mobile {
    display: flex;
  }
  .container-profile-body-filter-declare {
    padding-bottom: 10px;
  }
  .content-profile-body-filter-declare {
    &.first {
      flex-direction: column;
      width: 100%;
    }

    &.second {
      margin-top: 7px;
    }
  }
  .separator-profile-body-filter-declare {
    display: none;
  }
  .list-profile-body-filter-declare {
    margin-top: 20px;
    width: 100%;
  }
  .container-profile-footer {
    &.first {
      display: none;
    }

    &.second {
      flex-direction: column;
      align-items: flex-start;
    }

    .item-btn-footer-profile {
      margin-left: 0;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
