.wrapper-authorization-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
  }
}

.content-authorization-ulex {
  width: calc(100% - 120px);
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  padding: 32px 0 32px 0;
}

.container-authorization {
  &.first {
    width: 420px;
  }

  &.second {
    width: calc(100% - 400px);
    max-width: 640px;
  }
}

.item-authorization-icon {
  background: #F5F5F5;
  border-radius: 50%;
  width: 84px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 36px;
    height: 36px;
  }

  margin-bottom: 72px;
}

.item-authorization-title {
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 72px;

  span {
    color: #FC6B58;
  }

  margin-bottom: 20px;
}

.item-authorization-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  max-width: 350px;
  width: 100%;
  margin-bottom: 58px;
}

.item-authorization-link-registration {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;

  span {
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #FC6B58;
    }
  }
}

.item-authorization-link-registration-mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
  display: none;

  span {
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #FC6B58;
    }
  }
}

.form-authorization {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    outline: none;
    padding: 16px 0 16px 16px;
    margin-bottom: 24px;
  }
}

.item-authorization {
  display: flex;
  flex-direction: column;
}

.item-authorization-password {
  display: flex;
  flex-direction: column;
}

.btn-form-authorization {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #FC6B58;
  }
}

.item-or-authorization {
  position: relative;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 12px;
}

.title-or-authorization {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #808080;
  background-color: white;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px

}

.line-or-authorization {
  width: 100%;
  height: 1px;
  background-color: #DFDFDF;
}

.item-other-options-authorization {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
  row-gap: 12px;
}

.other-option-authorization {
  text-decoration: none;
  color: #000000;
  margin-top: 12px;
  width: calc(50% - 11px);
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #FC6B58;
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

  &.diya {
    border: none;
    background: linear-gradient(89.98deg, #4F40FF 0.02%, #FFAEE8 52.59%, #FF8484 100.74%);

    &:hover {
      color: white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.title-other-option-authorization {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-back-start-authorization {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    color: #FC6B58;
  }
}

.btn-close-authorization {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    color: #FC6B58;
  }
}

@media screen and (max-width: 1024px) {
  .content-authorization-ulex {
    width: calc(100% - 40px);
  }

}

@media screen and (max-width: 918px) {

  .content-authorization-ulex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 72px 0 54px 0;

  }
  .container-authorization {
    &.first {
      width: calc(100% - 40px);
      max-width: 388px;
    }

    &.second {
      width: calc(100% - 40px);
      max-width: 388px;
    }
  }

  .item-other-options-authorization {
    display: flex;
  }

  .item-authorization-icon {
    display: none;
  }
  .item-authorization-title {
    font-size: 46px;
    line-height: 52px;
    margin-bottom: 32px;
  }
  .item-authorization-info {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 54px;
  }
  .item-authorization-link-registration {
    display: none;
  }

  .item-authorization-link-registration-mobile {
    display: block;
    text-align: center;
    margin-top: 24px;
  }
  .item-or-authorization {
    margin-top: 32px;
    margin-bottom: 20px;
  }
  .other-option-authorization {
    width: 100%;
  }
  .btn-back-start-authorization {
    margin-top: 32px;
  }
}


