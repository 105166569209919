.post-side-bar {
  width: 20%;
  border: 1px solid #f5f5f5;
  padding: 25px;
  height: 95vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media screen and (max-width: 1024px) {
    width: 90%;
    border: none;
    border-top: 1px solid #f5f5f5;
    padding: 20px 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &__content {
    width: 100%;
  }

  &__head-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__head-text--post {
    margin: 0;
    color: #606060;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__head-text--interesting {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__posts {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item-result-search {
      width: 90%;
    }
  }
}
