.content-item-type-vehicle {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-vehicle {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-vehicle {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description-item-type-vehicle {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.container-select-item-type-vehicle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.title-entry-field-item-type-vehicle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}


.or-item-type-vehicle {
  position: relative;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.title-or-item-type-vehicle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #808080;
  background-color: white;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px

}

.line-or-item-type-vehicle {
  width: 100%;
  height: 1px;
  background-color: #DFDFDF;
}

.enter-data-manually-item-type-vehicle {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  svg {
    margin-right: 10px;
  }

  cursor: pointer;

  &:hover {
    border-color: #FC6B58;
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

}


.btn-item-type-vehicle {
  font-style: normal;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px;
  background: #000000;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 56px;

  &:hover {
    background-color: #FC6B58;
  }
}
