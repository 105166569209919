.rights {
  display: flex;
  max-width: 430px;
  max-height: calc(100vh - 40px);
  flex-direction: column;
  width: 430px;
  align-items: center;
  background-color: #ffffff;

  &__text {
    font-size: 15px;
  }
}