.item-result-search {
  display: flex;
  flex-direction: column;
  width: calc(25% - 18px);
  margin-right: 24px;

  &:nth-child(4) {
    display: flex;
    margin: 0;
    margin-bottom: 32px;
  }
  margin-bottom: 32px;
}

.item-result-search-null {
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #808080;
}

.img-item-result-search {
  width: 100%;
  height: 136px;
  background-color: rgba(255, 0, 0, 0.219);
  border-radius: 6px;
  object-fit: cover;
}

.title-item-result-search {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  &:hover {
    color: #fc6b58;
  }
}

.info-item-result-search {
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.date-info-item-result-search {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9f9f9f;
  display: flex;
  img {
    margin-right: 6px;
  }
}

.point-info-item-result-search {
  margin: 0 8px;
  width: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #606060;
}
.submit-info-item-result-search {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  display: flex;
  cursor: pointer;
  svg {
    margin-right: 4px;
  }
  &:hover {
    svg {
      fill: #fc6b58;
    }

    color: #fc6b58;
  }
}
