.wrapper-menu-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 10;
  flex-direction: column;
  align-items: center;
}

.content-menu-ulex {
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  padding: 32px 0 32px 0;
}

.title-menu {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}

.item-menu-nav {
  text-decoration: none;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }

  svg {
    margin-left: 8px;
  }
}

.title-item-menu-nav {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-decoration: none;
  color: #000000;
}

.item-line-menu {
  height: 1px;
  width: 100%;
  background-color: #DFDFDF;
  margin-top: 32px;
  margin-bottom: 18px;
}

.item-social-network-language-menu {
  display: flex;
}

.language-menu {
  margin-left: 6px;
}

.title-social-network-language-menu {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.container-social-network {
  display: flex;
  margin-top: 26px;
}

.item-social-network {
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 16px 16px 12px 16px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    border-color: #FC6B58;

    svg {
      fill: #FC6B58;
    }
  }
}

.container-language-network {
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  padding: 9px 32px 9px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin-top: 26px;
}


@media screen and (max-width: 1024px) {
  .content-menu-ulex {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 918px) {
  .content-menu-ulex {
    max-width: 388px;
  }
}
