.item-radio-item-type-questionnaire {
  margin-bottom: 0;
}

.itemStep5 {

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #404040;
    margin: 0 0 20px 0;
  }

  &__allData {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 32px;
    max-height: 320px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
    }
  }


}

input {
  border:none;
  background:none;
  outline:none;
  padding:0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.wrapper-step {
  display: flex;
  flex-direction: column;
}

.items-input-step {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 52px 0;

  &__blockInputs {
    display: flex;
    gap: 12px;
  }
}

.item-input-step {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #DFDFDF ;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  &.regiment {
    border-bottom: 1px solid transparent;
  }

  input {
    color: #000000;
    text-align: start;
    width: 70%;
    padding: 16px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.buttons-step {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-step {
  padding: 10px;
  border-top: 1px solid #DFDFDF ;
  margin-top: 26px;
}
