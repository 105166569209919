.wrapper-footer-ulex {
  display: flex;
  align-items: center;
  height: 64px;
  width: calc(100% - 120px);
  margin: 0 auto;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 745px) {
    flex-direction: column;
  }
}

.content-firstBlockFooter-ulex {
  height: 64px;
  display: flex;
  align-items: center;

  .title-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #808080;
  }
}
.content-lastBlockFooter-ulex {
  height: 64px;

  .iconWrapper-footer {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img:nth-child(1) {
      max-height: 34px;
    }
    img:nth-child(2) {
      max-height: 20px;
    }
  }
  .description-footer {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #808080;
    gap: 24px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
