.content-item-type-fineAmount {
  box-sizing: border-box;
  width: 100%;
  max-width: 648px;
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 6px 0px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 42px;
  position: relative;
}

.bang-item-type-fineAmount {
  position: absolute;
  max-width: 270px;
  width: 70%;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  background: #000000;
  border-radius: 0px 0px 32px 32px;
}

.title-item-type-fineAmount {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description-item-type-fineAmount {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.btn-item-type-fineAmount {
  font-style: normal;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px;
  background: #000000;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 56px;

  &:hover {
    background-color: #FC6B58;
  }
}

.entry-field-item-type-fineAmount {
  input {
    box-sizing: border-box;
    padding: 16px;
    outline: none;
    margin-top: 12px;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    border-radius: 0;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.title-entry-field-item-type-fineAmount {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
.dropdown-item-fineAmount {
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #000000;
  border-radius: 6px;
  appearance: none; /* Removes the default styling of select in some browsers */
  -webkit-appearance: none; /* Removes the default styling of select in Safari */
  -moz-appearance: none; /* Removes the default styling of select in Firefox */
  background-color: white;
  color: black;
}

.react-datepicker {
  border-color: #000000 !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #000000 !important;
  border: none !important;
}

.react-datepicker__current-month {
  color: #FFFFFF !important;
}

.react-datepicker__day-name {
  color: #FFFFFF !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #FC6B58 !important;
  color: #FFFFFF !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #000000 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #000000 !important;
}


.time-entry-field-item-type-fineAmount {
  display: flex;
  align-items: center;

  input {
    width: 56px;
    text-align: center;
  }

  span {
    margin: 0 12px;
    margin-top: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
