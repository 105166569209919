.wrapper-main-ulex {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-main-ulex {
  width: 100%;
  // max-width: 888px;
}

.title-main {
  margin-top: 74px;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 52px;
  letter-spacing: -0.02em;
  text-align: center;
  display: flex;
  justify-content: center;

  span {
    width: 620px;
    padding: 0 16px;
  }
}

.subtitle-main {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  display: flex;
  justify-content: center;

  span {
    width: 380px;
    padding: 0 16px;
  }
}

.container-circle-down-main {
  margin-top: 54px;
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.line-circle-down {
  height: 1px;
  background-color: #9F9F9F;;
  width: calc(100% - 32px);
  max-width: 348px;
}

.container-info-main {
  width: calc(100% - 32px);
  max-width: 888px;
  margin: 0 auto;
}

.content-info-main {
  box-sizing: border-box;
  padding: 32px 0;
  width: 100%;
  margin-top: 32px;
  background: #F5F5F5;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title-info-main {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.02em;
  width: 300px;
}

.btn-info-main {
  margin-top: 36px;
  display: flex;
  border: 1px solid white;

  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 11px 15px;
  cursor: pointer;

  svg {
    margin-left: 9px;
  }

  &:hover {
    color: #FC6B58;
    border-color: #FC6B58;
    fill: #FC6B58;
  }
}

.title-btn-info-main {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.container-swiper-main {
  width: calc(100% - 32px);
  margin: 0 auto;
  margin-top: 32px;
  max-width: 888px;
}

.swiper-main {
  width: 100%;
  height: calc(384px + 32px + 20px);
}

.swiper-wrapper {
  display: flex;
  justify-content: center; //added
}

.item-slide-main {
  margin-bottom: 24px;
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  width: 280px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 384px !important;

  img {
    width: 218px;
    height: 218px;
    margin: 20px 0;
  }
}

.swiper-pagination {
  display: flex;
  justify-content: flex-end;
}

.title-item-slide-main {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 26px;
}

.btn-item-slider-main {
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  width: calc(100% - 40px);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    color: white;
    border-color: #FC6B58;
    background-color: #FC6B58;
  }
}

.info-swiper-swipe-main {
  position: absolute;
  bottom: 6px;
  display: none;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #808080;

  img {
    margin-right: 6px;
  }
}

.swiper-pagination-bullet {
  width: 32px !important;
  border: 1px solid #DFDFDF;
  background-color: white !important;
  border-radius: 32px !important;
  opacity: 1 !important;
  margin: 0 0 0 6px !important;

  &.swiper-pagination-bullet-active {
    background-color: black !important;
  }
}

@media screen and (max-width: 918px) {
  .info-swiper-swipe-main {
    display: flex;
  }
  .wrapper-main-ulex {
    margin-bottom: 42px;
  }
}
