.dataFinishBlock {
  display: flex;
  flex-direction: column;
  background: #F0F0F4;
  border-radius: 6px;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
  }


  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    &__step {
      background: #E3E3E9;
      border-radius: 4px;
      padding: 4px;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #9F9F9F;
    }

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #606060;
      margin: 10px 0;
    }

    &__edit {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #DFDFDF;
      border-radius: 100%;
      padding: 10px;

      svg {
        min-height: 24px;
        min-width: 24px;
      }

      svg path {
        fill: #808080;
      }

    }
  }

  &__allItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    position: relative;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #606060;

    &__eye {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EAEAEA;
      border-radius: 32px;
      padding: 6px;

      svg {
        max-width: 16px;
        max-height: 16px;
      }
    }

    input {
      text-align: right;
    }
  }
}
